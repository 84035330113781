<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section-heading>
                <template v-slot:default>
                    Filters
                </template>

                <template v-slot:actions>
                    <div v-if="hasFilters">
                        <v-link color="primary" @click="clearFilters()">
                            Clear
                        </v-link>
                    </div>
                </template>
            </v-section-heading>

            <v-order-table-filters ref="orderTableFilters" v-model="filters" :parsed-filters="parsedFilters" />
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section-heading>
                <template v-slot:default>
                    Orders
                </template>

                <template v-slot:description>
                    A list of all the orders in the application.
                </template>

                <template v-slot:actions>
                    <div class="block lg:hidden">
                        <v-button color="primary" @click.stop="toggleSidebar">
                            <i class="fa fa-filter mr-2" />
                            Filter Results
                        </v-button>
                    </div>
                </template>
            </v-section-heading>

            <v-order-table :query="filters" />
        </template>
    </v-main>
</template>

<script>
import OrderTable from "@/components/application/order/order-table.vue";
import OrderTableFilters from "@/components/application/order/order-table-filters.vue";
import SectionHeading from "@/layouts/panel/section-heading.vue";
import Main from "@/layouts/panel/main.vue";

export default {
    components: {
        "v-order-table": OrderTable,
        "v-order-table-filters": OrderTableFilters,
        "v-section-heading": SectionHeading,
        "v-main": Main,
    },
    data() {
        return {
            /**
             * The filters applied to the orders on initial page load.
             *
             * All keys are required to avoid duplicate requests.
             */
            filters: {
                order_status_id: this.parsedFilters ? this.parsedFilters.order_status_id : null,
                has_fast_pass: null,
                game_platform_ids: [],
                player_environment_id: null,
                hours_passed: null,
                organization_id: null,
            },
        };
    },
    computed: {
        hasFilters() {
            return ((this.parseFilters(this.filters).length) > 0);
        },
        parsedFilters() {
            const filters = {
                has_fast_pass: this.$route.query.has_fast_pass ?? null,
                game_platform_ids: this.parseQueryString(this.$route.query.game_platform_ids) ?? [],
                player_environment_id: this.$route.query.player_environment_id ?? null,
                hours_passed: this.$route.query.hours_passed ?? null,
                organization_id: this.$route.query.organization_id ?? null,
            };
            const hasFilters = (this.parseFilters(filters).length) > 0;

            filters.order_status_id = this.$route.query.order_status_id ?? (hasFilters ? null : 1);

            return filters;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$forceUpdate();
        });
    },
    created() {
        // Filter orders by the query string parameters on initial page load.
        this.filters = { ...this.parsedFilters };
    },
    methods: {
        clearFilters() {
            this.$refs.orderTableFilters.clearFilters();
        },
        parseQueryString(str = null) {
            if (!str || !str.length) {
                return [];
            }

            if (Array.isArray(str)) {
                return str;
            }

            return [str];
        },
    },
};
</script>
