<template>
    <v-model-table v-bind="tableDataSource" module="orderModule" :columns="columns" :query="query">
        <template v-slot:column_order_id="{ row }">
            <v-link :to="{ name: 'orders.show', params: { order: row.order_id } }">
                {{ (row.order_id) }}
            </v-link>
        </template>

        <template v-slot:column_customer_name="{ row }">
            <v-text>
                {{ row.customer_name | customerName }}
            </v-text>
        </template>

        <template v-slot:column_order_status_name="{ row }">
            <v-badge :color="row.order_status_color">
                {{ row.order_status_name }}
            </v-badge>
        </template>

        <template v-slot:column_fast_pass_order_lines_count="{ row }">
            <v-text class="flex items-center justify-center">
                <svg class="h-5 w-5 text-white" :class="(row.fast_pass_order_lines_count > 0) ? 'text-secondary-500' : 'text-gray-500'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                </svg>
            </v-text>
        </template>
    </v-model-table>
</template>

<script>
export default {
    name: "OrderTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "orderModule",
            };
        },
        columns() {
            const tableColumns = {
                order_id: {
                    label: "Order",
                    align: "left",
                    fullWidth: false,
                },
                source_order_id: {
                    label: "Source Order",
                    align: "left",
                    fullWidth: false,
                },
                fast_pass_order_lines_count: {
                    label: "Fast Pass",
                    align: "center",
                    fullWidth: false,
                },
                customer_name: {
                    label: "Customer",
                    formatter: (row) => row.customer_name,
                    fullWidth: true,
                },
                order_status_name: {
                    label: "Status",
                    formatter: (row) => row.order_status_name,
                    align: "center",
                },
                available_over_total_order_lines: {
                    label: "Available Jobs",
                    align: "center",
                    formatter: (row) => `${row.available_order_lines_count} / ${row.order_lines_count}`,
                },
                created_at: {
                    label: "Created",
                },
            };

            return tableColumns;
        },
    },
};
</script>
