<template>
    <v-section-group v-if="filters">
        <v-section-divider>
            General Filters
        </v-section-divider>

        <v-section-group v-if="filters" class="grid grid-cols-1 gap-4 p-default">
            <v-form-select
                v-if="organizations.length > 2"
                v-model="filters.organization_id"
                :options="selectOptions(organizations, 'name', 'organization_id')"
                default-label="All Organizations"
            />

            <v-form-select
                v-model="filters.order_status_id"
                :options="selectOptions(orderStatuses, 'name', 'order_status_id')"
                default-label="All Statuses"
            />

            <v-form-select
                v-model="filters.player_environment_id"
                :options="selectOptions(playerEnvironments, 'name', 'player_environment_id')"
                default-label="All Environments"
            />

            <v-form-select
                v-model="filters.has_fast_pass"
                :options="fastPassOptions"
                default-label="All Passes"
            />

            <v-form-select
                v-model="filters.hours_passed"
                :options="hoursPassedOptions"
                default-label="All Time"
            />
        </v-section-group>

        <v-section-divider>
            Game Platforms
        </v-section-divider>

        <v-section-group class="grid grid-cols-1 gap-4 p-default">
            <template v-for="(gamePlatform, gamePlatformIndex) in gamePlatforms">
                <v-form-checkbox :key="'gamePlatform_' + gamePlatformIndex" v-model="filters.game_platform_ids" :value="gamePlatform.game_platform_id">
                    <span class="block">
                        {{ gamePlatform.name }}
                    </span>
                </v-form-checkbox>
            </template>
        </v-section-group>
    </v-section-group>
</template>

<script>
import TableFiltersMixin from "@/mixins/table-filters";
import OrderStatusService from "@/services/modules/order-status-service";
import GamePlatformService from "@/services/modules/game-platform-service";
import PlayerEnvironmentService from "@/services/modules/player-environment-service";
import UserService from "@/services/modules/user-service";

export default {
    name: "OrderTableFilters",
    mixins: [
        TableFiltersMixin,
    ],
    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        parsedFilters: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: { ...this.parsedFilters },

            /**
             * @todo Write a description.
             */
            orderStatuses: [],

            /**
             * @todo Write a description.
             */
            gamePlatforms: [],

            /**
             * @todo Write a description.
             */
            playerEnvironments: [],

            /**
             * @todo Write a description.
             */
            organizations: [],

            /**
             * @todo Write a description.
             */
            fastPassOptions: [
                {
                    label: "All Passes",
                    value: null,
                },
                {
                    label: "Has Fast Pass",
                    value: true,
                },
                {
                    label: "Has No Fast Pass",
                    value: false,
                },
            ],

            /**
             * @todo Write a description.
             */
            hoursPassedOptions: [
                {
                    label: "All Time",
                    value: null,
                },
                {
                    label: "Older Than 24H",
                    value: 24,
                },
            ],
        };
    },
    created() {
        OrderStatusService.index()
            .then((response) => {
                this.orderStatuses = [
                    {
                        name: "All Statuses",
                        order_status_id: null,
                    },
                    ...response.data.data,
                ];
            });

        GamePlatformService.index()
            .then((response) => {
                this.gamePlatforms = [
                    ...response.data.data,
                ];
            });

        PlayerEnvironmentService.index()
            .then((response) => {
                this.playerEnvironments = [
                    {
                        name: "All Environments",
                        player_environment_id: null,
                    },
                    ...response.data.data,
                ];
            });

        UserService.getOrganizations(this.$me.getUser().user_id)
            .then((response) => {
                this.organizations = [
                    {
                        name: "All Organizations",
                        organization_id: null,
                    },
                    ...response.data.data,
                ];
            });
    },
    methods: {
        clearFilters() {
            this.filters = { game_platform_ids: [] };
        },
    },
};
</script>
